import axios from 'axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
axios.defaults.headers['Content-Language'] = 'zh_CN'
const service = axios.create({
//   baseURL:'http://localhost:5162', 
  timeout: 0
})

const get = (url, params) => {
    return service({
      url: url,
      method: 'get',
      params: params
    })
  }
export default get

<template>
  <el-container>
    <el-header >
      <span class="demonstration">开始时间</span>
      <el-date-picker
      v-model="queryParams.from"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择日期">
    </el-date-picker>  
    <span class="demonstration">结束时间</span>
    <el-date-picker
      v-model="queryParams.to"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择日期">
    </el-date-picker>
    <el-button type="primary" @click="handleQuery">查询</el-button>
    <el-button type="primary" @click="downFile">导出</el-button>
  </el-header>
    <el-main>
      <el-table  v-loading="loading" :data="tableList">
    <el-table-column
      type="index"
      width="50">
    </el-table-column>
        <el-table-column
            v-for="(row, index) in tableColumns"
            :key="index"
            :label="row.label"
            :prop="row.key">
        </el-table-column>
    <el-table-column
      prop="description"
      label="工作说明">
            <template slot-scope="scope" >
              <div  v-html='scope.row.description'>
              </div>
            </template>
    </el-table-column>
      </el-table>
    </el-main>
    <el-footer>
    </el-footer>
  </el-container>
</template>

<script>
// export default {
//   name: 'HelloWorld',
//   props: {
//     msg: String
//   }
// }
import api from '@/api/api'
import dayjs from "dayjs"

export default {
  data() {
    return{
      total:0,
      // 遮罩层
      loading: false,
      // 表数据
      tableList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        from:new Date(),
        to:new Date(),
      },
      tableColumns : [
        {
            key: 'title',
            label: '事项'
        }, {
            key: 'duration',
            label: '工作时长',
        }, {
            key: 'display_name',
            label: '登记人',
        }, {
            key: 'report_at',
            label: '日期',
        }, {
            key: 'identifier',
            label: '所属标识',
        }
        // , {
        //     key: 'description',
        //     label: '工作说明',
        // }
      ]
    }
  },
  
  activated() {
      this.getList();
  },
  methods: {

    getList() {
      this.loading = true;
      api.listTable(this.queryParams).then(response => {
          this.tableList = response.data.data;
          this.total = response.data.total;
          this.loading = false;
        }
      );
    },
    
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    downFile () {
      let start= dayjs(this.queryParams.from).format('YYYY-MM-DD');
      let end= dayjs(this.queryParams.to).format('YYYY-MM-DD');
      // window.open(
      //   `/api/WorkItem/export?from=`+start+'&to'+end
      // );
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `/api/WorkItem/export?from=`+start+'&to='+end);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          // link.download = fileName;
          link.click();
        }
      };
      xhr.send();
    },

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
